import { lazy } from 'react'
import { RouterType } from '@Types/router'
import * as Paths from '@Constants/paths'

const UserLayoutRoutes: RouterType[] = [
    {
        path: Paths.SIGNUP_PATH,
        meta: {
            title: 'Welcome to Laika',
        },
        component: lazy(() => import('@Pages/Auth/SignUpPage/SignUpPage')),
    },
    {
        path: Paths.SIGNUP_PATH_DONE,
        meta: {
            title: 'You did it!',
        },
        component: lazy(() => import('@Pages/Auth/SignUpDonePage/SignUpDonePage')),
    },
]

export default UserLayoutRoutes
