import { memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// Styles
import styles from './styles.module.sass'

import useTitle from '@Hooks/useTitle'
import { formatRoutes } from '@Utils/router'
import layoutRotes from './routes'

type SignUpLayoutProps = {
    children: React.ReactNode
}

export default memo(({ children }: SignUpLayoutProps) => {
    const location = useLocation()

    const routerPathKeyRouter = useMemo(() => formatRoutes(layoutRotes), [])

    const routeItem = useMemo(
        () => routerPathKeyRouter.pathKeyRouter[location.pathname],
        [location, routerPathKeyRouter.pathKeyRouter]
    )

    useTitle(routeItem?.meta?.title || '')

    return <div className={styles.container}>{children}</div>
})
