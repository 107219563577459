// Constants
import * as Paths from '@Constants/paths'

// Types
import { NavItem } from '@Types/menu'

export const MainNavItems = [
    {
        id: 'dashboard-item',
        title: 'Dashboard',
        iconName: 'house',
        url: Paths.DASHBOARD_PATH,
        visible: true,
        isNetworkUrl: true,
    },
    {
        id: 'reviews-item',
        title: 'Reviews',
        iconName: 'comments',
        url: Paths.REVIEWS_PATH,
        visible: true,
        isNetworkUrl: true,
    },
] as NavItem[]

export const SecondNavItems = [
    {
        id: 'promo-item',
        title: 'Promo materials',
        iconName: 'publication',
        url: Paths.PROMO_MATERIALS_PATH,
        visible: true,
        isNetworkUrl: true,
    },
    {
        id: 'settings-item',
        title: 'Settings',
        iconName: 'settings',
        url: Paths.SETTINGS_PLATFORMS_PATH,
        visible: true,
        isNetworkUrl: true,
    },
] as NavItem[]
