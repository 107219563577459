import React from 'react'
import Error500 from '@Components/Errors/Error500'

export default class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError() {
        return {
            hasError: true
        }
    }

    render() {
        const { children, component } = this.props
        const { hasError } = this.state

        if (hasError) {
            if (component) {
                const Component = component
                return <Component />
            } else {
                return <Error500 />
            }
        }

        return <>{children}</>
    }
}
