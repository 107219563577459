import { Middleware, configureStore, TypedStartListening } from '@reduxjs/toolkit'
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'

import application from '@Slices/appSlice'
import user from '@Slices/userSlice'
import signup from '@Slices/signupSlice'
import networks from '@Slices/networksSlice'
import reviews from '@Slices/reviewsSlice'
import reviewRequests from '@Slices/reviewRequestsSlice'
import integrations from '@Slices/integrationsSlice'

import { listenerMiddleware, loggerMiddleware } from '@Store/middleware'

const ADDED_MIDDLEWARES: Middleware[] = [loggerMiddleware]

const defaultMiddlewareOptions = {
    serializableCheck: false,
}

const addedMiddlewares = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
    const middlewares = ADDED_MIDDLEWARES

    if (middlewares.length > 0) {
        return getDefaultMiddleware(defaultMiddlewareOptions)
            .prepend(listenerMiddleware)
            .concat(...middlewares)
    }

    return getDefaultMiddleware()
}

const store = configureStore({
    reducer: {
        application,
        user,
        signup,
        networks,
        reviews,
        reviewRequests,
        integrations,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => addedMiddlewares(getDefaultMiddleware),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
