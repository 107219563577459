import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import cn from 'classnames'
import styles from '@Styles/UiKit/Tabs/common.module.sass'

type Props = {
    label: string
    active: boolean
    url: string
}

export default function HeadTab({ label, url, active }: Props): JSX.Element {
    return (
        <Link to={url} className={cn(styles.headTab, { [styles.headTab_active]: active })}>
            <div className={cn(styles.headTab__label)}>{label}</div>
        </Link>
    )
}
