import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import request from '@Utils/request'
import { startAppListening } from '@Store/middleware'

// Slices
import { loadIntegrations } from './integrationsSlice'

// Types
import { RootState } from '@Store/store'
import { NetworksState, Upload, Network, Branch } from '@Types/store/networks'
import { RequestState } from '@Types/enums/requestState'
import { LoadingDataState } from '@Types/enums/loadingDataState'

export const doNetworksLoad = createAsyncThunk('networks/load', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await request({
            url: '/networks',
            method: 'GET',
        })

        return response.data as AxiosResponse<{ networks: Network[] }>
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const doNetworkBranchesLoad = createAsyncThunk(
    'branches/load',
    async (params: { networkUuid: string }, { rejectWithValue }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/branches`,
                method: 'GET',
            })

            return response.data as AxiosResponse<{ branches: Branch[] }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const doUploadNetworkLogo = createAsyncThunk(
    'networks/uploadLogo',
    async (data: FormData, { rejectWithValue, getState }) => {
        const { user, networks } = getState() as RootState
        const networkUserUuid = user.me?.networks?.[0].uuid
        const networkUuid = networks.currentNetwork?.uuid

        try {
            const response = await request({
                url: `/networks/${networkUserUuid || networkUuid}/upload-logo`,
                headers: { 'Content-Type': 'multipart/form-data' },
                method: 'put',
                data,
            })

            return response.data as AxiosResponse<{ uploads: Upload[] }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const initialState = {
    logoUploading: RequestState.IDLE,
    loading: LoadingDataState.IDLE,
    networks: [],
    loadingBranches: LoadingDataState.IDLE,
    branches: [],
    networksMap: null,
    currentNetwork: null,
} as NetworksState

const networksSlice = createSlice({
    name: 'networks',
    initialState,
    reducers: {
        setCurrentNetwork(state, action) {
            state.currentNetwork = action.payload.network || null
        },
        changeCurrentNetwork(state, action) {
            state.currentNetwork = action.payload.network || null
        },
    },
    extraReducers: builder => {
        builder
            .addCase(doNetworksLoad.pending, state => {
                if (state.loading === LoadingDataState.IDLE) {
                    state.loading = LoadingDataState.LOADING
                }
            })
            .addCase(doNetworksLoad.fulfilled, (state, action: any) => {
                state.loading = LoadingDataState.LOADED

                const networks = action.payload.networks
                if (networks.length > 0) {
                    state.networks = networks
                    state.networksMap = new Map(
                        action.payload.networks.map((network: Network) => [network.uuid, network])
                    )

                    // TODO - возможно временное решение,
                    // так как пока connected-react-router не поддерживается для react router v6
                    // мне пришлось установку текущей сети по URL параметру унести в
                    // src/scripts/layouts/DashboardLayout/DashboardLayout.tsx
                    // а при регистрации нужно установить текущую сеть для последующей загрузки логотипа и
                    // подключения интеграций
                    if (networks?.length === 1) {
                        state.currentNetwork = networks[0]
                    }
                }
            })
            .addCase(doNetworksLoad.rejected, state => {
                if (state.loading === LoadingDataState.LOADING) {
                    state.loading = LoadingDataState.ERROR
                }
            })

            .addCase(doNetworkBranchesLoad.pending, state => {
                if (state.loadingBranches === LoadingDataState.IDLE) {
                    state.loadingBranches = LoadingDataState.LOADING
                }
            })
            .addCase(doNetworkBranchesLoad.fulfilled, (state, action: any) => {
                state.loadingBranches = LoadingDataState.LOADED

                const branches = action.payload.branches
                if (branches.length > 0) {
                    state.branches = branches
                }
            })
            .addCase(doNetworkBranchesLoad.rejected, state => {
                if (state.loadingBranches === LoadingDataState.LOADING) {
                    state.loadingBranches = LoadingDataState.ERROR
                }
            })

            .addCase(doUploadNetworkLogo.pending, state => {
                if (state.logoUploading === RequestState.IDLE) {
                    state.logoUploading = RequestState.PENDING
                }
            })
            .addCase(doUploadNetworkLogo.fulfilled, (state, action: any) => {
                state.logoUploading = RequestState.SUCCESS
            })
            .addCase(doUploadNetworkLogo.rejected, state => {
                if (state.logoUploading === RequestState.PENDING) {
                    state.logoUploading = RequestState.IDLE
                }
            })
    },
})

export const { setCurrentNetwork, changeCurrentNetwork } = networksSlice.actions

export default networksSlice.reducer

// Listeners middleware

// После загрузки сетей обновляем список интеграций
// startAppListening({
//     type: 'networks/load/fulfilled',
//     effect: async (action: any, { dispatch, getState }) => {
//         const state = getState()
//         const network = state.networks.currentNetwork
//         if (network?.uuid) {
//             dispatch(loadIntegrations({ networkUuid: network.uuid }))
//         }
//     },
// })

// После загрузки сетей и определении какая сеть текущая
// загружаем интеграции и список филиалов, но пока филиал может быть только один
startAppListening({
    type: 'networks/setCurrentNetwork',
    effect: async (action: any, { dispatch }) => {
        dispatch(loadIntegrations({ networkUuid: action.payload.network.uuid }))
        dispatch(doNetworkBranchesLoad({ networkUuid: action.payload.network.uuid }))
    },
})

// После переключения текущей сети обновляем список интеграций и список филиалов, но пока филиал может быть только один
startAppListening({
    type: 'networks/changeCurrentNetwork',
    effect: async (action: any, { dispatch }) => {
        dispatch(loadIntegrations({ networkUuid: action.payload.network.uuid }))
        dispatch(doNetworkBranchesLoad({ networkUuid: action.payload.network.uuid }))
    },
})

// После загрузки логотипа обновляем сети
startAppListening({
    type: 'networks/uploadLogo/fulfilled',
    effect: async (_, { dispatch }) => {
        dispatch(doNetworksLoad())
    },
})
