import { Location } from 'history'
import * as Paths from '@Constants/paths'

export function getUrlWithRedirect(location: Location): string {
    let redirectUrl = Paths.LOGIN_PATH

    if (location.pathname !== '') {
        const query = new URLSearchParams()
        const { pathname, search } = location
        query.set('redirectUrl', pathname + search)
        redirectUrl = `${redirectUrl}?${query}`
    }

    return redirectUrl
}

export function getRedirectUrl(location: Location): string {
    const query = new URLSearchParams(location.search)
    return query.get('redirectUrl') || '/'
}
