export const PROFILE_PATH = '/profile'

export const AUTH_PATH = '/auth'
export const LOGIN_PATH = `${AUTH_PATH}/login`
export const SIGNUP_PATH = '/signup'
export const SIGNUP_PATH_DONE = '/signup/done'
export const RESET_PASSWORD_PATH = `${AUTH_PATH}/restore`
export const DASHBOARD_MAIN_PATH = '/dashboard'
export const DASHBOARD_PATH = '/dashboard/:networkUuid'
export const REVIEWS_PATH = '/dashboard/:networkUuid/reviews'
export const REVIEW_PATH = '/dashboard/:networkUuid/reviews/:reviewUuid'
export const REVIEWS_REQUESTS_PATH = '/dashboard/:networkUuid/reviews-requests'
export const REQUEST_REVIEWS_PATH = '/dashboard/:networkUuid/request-reviews'
export const PROMO_MATERIALS_PATH = '/dashboard/:networkUuid/promo'
export const SETTINGS_PLATFORMS_PATH = '/dashboard/:networkUuid/settings-platforms'

export const PAGE_500 = '/500'
export const PAGE_403 = '/403'
