import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Actions
import { doUserLoadMe } from '@Slices/userSlice'

// Types
import { AppStatus, AppState } from '@Types/store/app'

export const doAppInit = createAsyncThunk('app/init', async (_, { dispatch, getState, rejectWithValue }) => {
    await dispatch(doUserLoadMe())
        .then(() => {
            const state = getState() as any
            if (state.user.me) {
                return true
            } else {
                return rejectWithValue(null)
            }
        })
        .catch((error: any) => {
            return rejectWithValue(null)
        })
})

const initialState = {
    status: AppStatus.IDLE,
} as AppState

const userSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(doAppInit.pending, state => {
                state.status = AppStatus.INITIALIZING
            })
            .addCase(doAppInit.fulfilled, (state, action: any) => {
                state.status = AppStatus.INITIALIZED
            })
            .addCase(doAppInit.rejected, state => {
                state.status = AppStatus.IDLE
            })
    },
})

export default userSlice.reducer
