import React, { memo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux'

// Utils
import { getUrlWithRedirect } from '@Utils/redirect'
import ErrorBoundary from '@Hocs/ErrorBoundary'

// Types
import { RootState } from '@Store/store'
import { UserStatus } from '@Types/enums/userStatus'

// Constants
import * as Paths from '@Constants/paths'

// Components
import PageLoader from '@Components/Common/Loaders/PageLoader/PageLoader'

export type SecurityLayoutProps = {
    children: React.ReactNode
}

export default memo(({ children }: SecurityLayoutProps) => {
    const location = useLocation()
    const navigate = useNavigate()

    const { networks } = useSelector((state: RootState) => state.networks)
    const { me, status } = useSelector((state: RootState) => state.user)

    // Если пользователь не авторизован то кидаем на страницу авторизации
    if (!me?.uuid && status === UserStatus.loggedOut) {
        const newPath = getUrlWithRedirect(location)
        navigate(newPath)
    }

    if (me?.uuid && networks.length > 0 && location.pathname === '/') {
        const networkUuid = networks[0].uuid
        navigate(Paths.DASHBOARD_PATH.replace(':networkUuid', networkUuid))
    }

    // TODO - разобраться с редиректом
    useEffect(() => {
        if (me?.uuid && networks.length > 0 && location.pathname === '/') {
            const networkUuid = networks[0].uuid
            navigate(Paths.DASHBOARD_PATH.replace(':networkUuid', networkUuid))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isLogin = status === UserStatus.loggedIn

    return <React.Fragment>{isLogin ? <ErrorBoundary>{children}</ErrorBoundary> : <PageLoader />}</React.Fragment>
})
