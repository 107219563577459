import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import styles from './styles.module.sass'
import cn from 'classnames'

type Props = {
    id: string
    title: string
    url: string
    active: boolean
    icon: JSX.Element
    onClick: () => void
}

export default function SideNavItem({ id, title, icon, url, active, onClick }: Props): JSX.Element {
    return (
        <Link
            id={id}
            to={url}
            className={cn(styles.sideNavItem, { [styles.sideNavItem_active]: active })}
            onClick={onClick}
        >
            <div className={cn(styles.sideNavItem__icon)}>{icon}</div>
            <div className={cn(styles.sideNavItem__title)}>{title}</div>
        </Link>
    )
}
