import React, { memo, useMemo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux'

// Utils
import { getRedirectUrl } from '@Utils/redirect'

// Styles
import styles from './styles.module.sass'

import useTitle from '@Hooks/useTitle'
import { formatRoutes } from '@Utils/router'
import layoutRotes from './routes'

// Components
import LaikaLogo from '@Components/Common/Logos/LaikaLogo/LaikaLogo'

// Types
import { AppStatus } from '@Types/store/app'
import { UsersState } from '@Types/store/user'
import { AppState } from '@Types/store/app'
import { UserStatus } from '@Types/enums/userStatus'

export type AuthLayoutProps = {
    children: React.ReactNode
}

export default memo(({ children }: AuthLayoutProps) => {
    const location = useLocation()
    const navigate = useNavigate()

    const { me, status: userStatus } = useSelector((state: any) => state.user) as UsersState
    const { status: appStatus } = useSelector((state: any) => state.application) as AppState

    const routerPathKeyRouter = useMemo(() => formatRoutes(layoutRotes), [])

    const routeItem = useMemo(
        () => routerPathKeyRouter.pathKeyRouter[location.pathname],
        [location, routerPathKeyRouter.pathKeyRouter]
    )

    useEffect(() => {
        if (userStatus === UserStatus.loggedIn && appStatus === AppStatus.INITIALIZED) {
            const redirectUrl = getRedirectUrl(location) || '/'
            navigate(redirectUrl)
        }
    }, [userStatus, appStatus, location, navigate])

    useTitle(routeItem?.meta?.title || '')

    const isLogin = Boolean(me?.uuid && userStatus === UserStatus.loggedIn)

    return (
        <React.Fragment>
            {isLogin === false && appStatus === AppStatus.INITIALIZED ? (
                <div className={styles.container}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.content}>
                            <header className={styles.header}>
                                <LaikaLogo />
                            </header>
                            <section className={styles.form}>{children}</section>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    )
})
