import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from '@Containers/App'
import { Provider } from 'react-redux'
import store from '@Store/store'
import ErrorBoundary from '@Hocs/ErrorBoundary'

// Sentry
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

// Styles
import '@Styles/index.sass'
import './index.css'

// Constants
import { config } from '@Config/config'

if (!config.IS_DEV_APP && !config.IS_LOCAL_APP) {
    Sentry.init({
        dsn: 'https://61f480efeb1549349d06e5317daf5b01@o4504326974668800.ingest.sentry.io/4504326980370432',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

const root = ReactDOM.createRoot(document.getElementById('laika-root') as HTMLElement)

root.render(
    <ErrorBoundary>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>
)
