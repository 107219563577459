import settings from '@Config/settings'

export const getToken = () => localStorage.getItem(settings.siteTokenKey)

export const setToken = (token: string) => {
    localStorage.setItem(settings.siteTokenKey, token)
}

export const removeToken = () => {
    localStorage.removeItem(settings.siteTokenKey)
}
