import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getToken } from '@Utils/localToken'
import { config } from '@Config/config'
import { createToast, TOAST_TYPES } from '@Components/Common/Toast/Toast'

import settings from '@Config/settings'

export type ResponseData<T = unknown> = {
    code: number
    data?: T
    msg?: string
}

// const serverCodeMessage: { [key: number]: string } = {
//     200: 'OK',
//     400: 'Bad Request',
//     401: 'Unauthorized',
//     403: 'Forbidden',
//     404: 'Not Found',
//     500: 'Internal Server Error',
//     502: 'Bad Gateway',
//     503: 'Service Unavailable',
//     504: 'Gateway Timeout'
// }

// const customCodeMessage: { [key: number]: string } = {
//     10002: 'Your session has expired. Please log in.',
// }

const errorHandler = (error: any) => {
    const { response, message } = error
    // console.log('response :', response)
    // console.log('response.status :', response.status)
    // console.log('response :', response && response.status)

    if (message === 'CustomError') {
        const { config, data } = response
        const { url, baseURL } = config
        const { code /*, msg*/ } = data
        const reqUrl = url.split('?')[0].replace(baseURL, '')
        const noVerifyBool = settings.ajaxResponseNoVerifyUrl.includes(reqUrl)

        if (!noVerifyBool) {
            // notification.error({
            //     message: `Уведомление`,
            //     description: customCodeMessage[code] || msg || 'Error',
            // })
            //alert(customCodeMessage[code]) // TODO - добавить тостер

            if (code === 10002) {
                setTimeout(() => {
                    window.location.href = '/auth/login'
                }, 500)
            }
        }
    } else if (message === 'CancelToken') {
        // Cancel request Token
        // eslint-disable-next-line no-console
        // console.log(message)
    } else if (response && response.status) {
        // const errorText = serverCodeMessage[response.status] || response.statusText
        // const { status, request } = response
        // createToast(errorText, `Request error ${status}`, { type: TOAST_TYPES.ERROR })
    } else if (!response) {
        createToast('Your network is abnormal and cannot connect to the server', 'Network exception', {
            type: TOAST_TYPES.ERROR,
        })
    }

    return Promise.reject(error)
}

const request = axios.create({
    baseURL: config.API_ROOT || '', // url = api url + request url
    withCredentials: true, // Отправляется при междоменном запросе cookie
    timeout: 0, // Тайм-аут запроса, 5000 (миллисекунды) / 0 - без ограничений
})

// Глобальные настройки -заголовок запроса post
// request.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/**
 * Перед запросом
 * Перехватчик запросов
 */
request.interceptors.request.use((config: AxiosRequestConfig & { cType?: boolean }) => {
    // Если установлен cType, это означает, что тип content-Type настроен таким образом,
    // чтобы проложить путь для пользовательской записи.
    if (config.cType) {
        if (!config.headers) {
            config.headers = {}
        }

        //config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        config.headers['Content-Type'] = 'application/json'
        //config.headers['X-Hostname'] = new URL(config.API_ROOT).hostname
    }

    // Пользовательское добавление token header
    const headerToken = getToken()
    if (headerToken) {
        if (!config.headers) {
            config.headers = {}
        }
        config.headers[settings.ajaxHeadersTokenKey] = headerToken
    }

    return config
})

request.interceptors.response.use(
    (response: AxiosResponse<ResponseData>) => {
        const res = response.data
        const { code } = res

        if (code !== 0 && code !== undefined) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
                response,
                message: 'CustomError',
            })
        }

        return response
    }
    // error => {
    //     // eslint-disable-next-line no-console
    // }
)

export default function ajax<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig & { cType?: boolean }
): AxiosPromise<R> {
    return request(config)
        .then((response: AxiosResponse) => response)
        .catch(error => errorHandler(error))
}
