export const SIGNUP_NAME = 'signupName'
export const SIGNUP_EMAIL = 'signupEmail'
export const SIGNUP_CATEGORY_ID = 'signupCategoryId'
export const SIGNUP_BUSINESS_NAME = 'signupBusinessName'
export const GOOGLE_MAP_AVAILABLE_NAME = 'google-maps-available-panel'

export default class LocalStorage {
    static get = (key: any, defaultValue = null) => {
        try {
            const value = localStorage.getItem(key)
            return value ? JSON.parse(value) : defaultValue
        } catch (error) {
            return defaultValue
        }
    }

    static set = (key: any, value: string | boolean) => {
        try {
            localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            return null
        }
    }

    static remove = (key: any) => {
        try {
            localStorage.removeItem(key)
        } catch (error) {
            return null
        }
    }
}
