import { lazy } from 'react'
import { RouterType } from '@Types/router'

import * as Paths from '@Constants/paths'
import { ReviewsPageTabs, SettingsPageTabs } from '@Constants/tabs'

export const DashboardLayoutRoutes: RouterType[] = [
    {
        path: Paths.DASHBOARD_PATH,
        meta: {
            title: 'Dashboard',
            showRequestReviewButton: true,
        },
        component: lazy(() => import('@Pages/Dashboard/DashboardPage/DashboardPage')),
    },
    {
        path: Paths.REVIEWS_PATH,
        meta: {
            title: 'Reviews',
            showRequestReviewButton: true,
            tabs: ReviewsPageTabs,
        },
        component: lazy(() => import('@Pages/Dashboard/ReviewsPage/ReviewsPage')),
    },
    {
        path: Paths.REVIEW_PATH,
        meta: {
            title: 'Review page',
            hideHeadTitle: true,
        },
        component: lazy(() => import('@Pages/Dashboard/ReviewPage/ReviewPage')),
    },
    {
        path: Paths.REVIEWS_REQUESTS_PATH,
        meta: {
            title: 'Review requests',
            showRequestReviewButton: true,
            tabs: ReviewsPageTabs,
        },
        component: lazy(() => import('@Pages/Dashboard/ReviewsRequestsPage/ReviewsRequestsPage')),
    },
    {
        path: Paths.REQUEST_REVIEWS_PATH,
        meta: {
            title: 'Request review',
            hideHeadTitle: true,
        },
        component: lazy(() => import('@Pages/Dashboard/ReviewsRequestPage/ReviewsRequestPage')),
    },
    {
        path: Paths.PROMO_MATERIALS_PATH,
        meta: {
            title: 'Promo materials',
        },
        component: lazy(() => import('@Pages/Dashboard/PromoMaterialsPage/PromoMaterialsPage')),
    },
    {
        path: Paths.SETTINGS_PLATFORMS_PATH,
        meta: {
            title: 'Settings',
            tabs: SettingsPageTabs,
        },
        component: lazy(() => import('@Pages/Dashboard/SettingsPlatformPage/SettingsPlatformPage')),
    },
]

export default DashboardLayoutRoutes
