import React from 'react'
import ScrollLock from 'react-scrolllock'

// Styles
import cn from 'classnames'
import commonStyles from '@Styles/common.module.sass'

type Props = {
    isOpen: boolean
    onClickMenuToggle: () => void
    zIndex: number
    children: any
}

const SidePop = ({ isOpen, onClickMenuToggle, children, zIndex }: Props) => {
    return (
        <React.Fragment>
            <aside
                className={cn(commonStyles.sidebar, {
                    [commonStyles.sidebar_isClose]: isOpen,
                    [commonStyles.sidebar_isOpen]: !isOpen,
                })}
                style={{ zIndex: isOpen ? zIndex : 0 }}
            >
                {isOpen && <ScrollLock />}
                {children}
            </aside>
            <div
                className={cn(commonStyles.scrim, {
                    [commonStyles.isOpen]: !isOpen,
                    [commonStyles.isClose]: isOpen,
                })}
                style={{ zIndex: isOpen ? zIndex - 1 : 0 }}
                onClick={onClickMenuToggle}
            />
        </React.Fragment>
    )
}

SidePop.defaultProps = {
    isOpen: false,
    handleToggle: () => {},
    classNames: [],
    zIndex: 999,
}

export default SidePop
