import React, { useCallback } from 'react'
import { PathMatch } from 'react-router-dom'

// Styles
import cn from 'classnames'
import styles from '@Styles/UiKit/Tabs/common.module.sass'

// Constants
import HeadTab from './HeadTab'

// Types
import { ReviewsHeadTab } from '@Types/tabs'
import { RouterType } from '@Types/router'

type Props = {
    tabs: ReviewsHeadTab[]
    dashboardMatching: { match: PathMatch; routeConfig: RouterType } | null
}

export default function HeadTabs({ tabs, dashboardMatching }: Props): JSX.Element {
    const networkUuidParam = dashboardMatching?.match.params.networkUuid || ''
    const pattern = dashboardMatching?.match.pattern || ''

    const isActiveItem = useCallback(
        (tab: ReviewsHeadTab) => {
            return Boolean(pattern && pattern.path === tab.url)
        },
        [pattern]
    )

    return (
        <div className={cn(styles.blockTabs)}>
            {tabs.map((tab, index: number) => {
                return (
                    <HeadTab
                        key={index}
                        label={tab.label}
                        active={isActiveItem(tab)}
                        url={tab.url.replace(':networkUuid', networkUuidParam)}
                    />
                )
            })}
        </div>
    )
}
