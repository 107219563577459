import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { startAppListening } from '@Store/middleware'

// Utils
import { getErrorsMap } from '@Utils/errors'
import request from '@Utils/request'
import LocalStorage from '@Services/LocalStorage'
import * as LocalStorageName from '@Services/LocalStorage'

// Slices
import { updateUser } from '@Slices/userSlice'
import { doNetworksLoad } from '@Slices/networksSlice'

// Types
import { RootState } from '@Store/store'
import { ResponseSignupBodyBranch, SignupState } from '@Types/store/signup'
import { RequestState } from '@Types/enums/requestState'

export const doSignUp = createAsyncThunk('auth/signup', async (_, { rejectWithValue, getState }) => {
    const { signup } = getState() as RootState
    const data = signup.multiStepFormFields

    try {
        const response = await request({ url: '/auth/signup', method: 'POST', data, cType: true })

        return response.data as AxiosResponse<ResponseSignupBodyBranch>
    } catch (err: any) {
        if (!err?.response) {
            throw err
        }

        return rejectWithValue(err.response.data)
    }
})

const emailFromLS = LocalStorage.get(LocalStorageName.SIGNUP_EMAIL)
const nameFromLS = LocalStorage.get(LocalStorageName.SIGNUP_NAME)
const categoryIdFromLS = LocalStorage.get(LocalStorageName.SIGNUP_CATEGORY_ID)
const businessNameFromLS = LocalStorage.get(LocalStorageName.SIGNUP_BUSINESS_NAME)

const initialState = {
    loading: RequestState.IDLE,
    multiStepFormFields: {
        email: emailFromLS || '',
        name: nameFromLS || '',
        password: '',
        categoryId: categoryIdFromLS || null,
        businessName: businessNameFromLS || '',
        fullAddress: '',
        latitude: 0,
        longitude: 0,
        postcode: null,
    },
    errorsFieldsMap: null,
} as SignupState

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        updateSignupFieldsStep1(state, action) {
            const email = action.payload.email || ''
            const name = action.payload.name || ''
            const password = action.payload.password || ''
            state.multiStepFormFields.email = email
            state.multiStepFormFields.name = name
            state.multiStepFormFields.password = password
            LocalStorage.set(LocalStorageName.SIGNUP_NAME, name)
            LocalStorage.set(LocalStorageName.SIGNUP_EMAIL, email)
        },
        updateSignupFieldsStep2(state, action) {
            const categoryId = action.payload.categoryId || ''
            const businessName = action.payload.businessName || ''
            state.multiStepFormFields.categoryId = categoryId
            state.multiStepFormFields.businessName = businessName
            LocalStorage.set(LocalStorageName.SIGNUP_CATEGORY_ID, categoryId)
            LocalStorage.set(LocalStorageName.SIGNUP_BUSINESS_NAME, businessName)
        },
        updateSignupFieldsStep3(state, action) {
            const fullAddress = [
                action.payload.location.country,
                action.payload.location.city,
                action.payload.location.address,
            ].join(', ')

            const latitude = action.payload.location.center.lat
            const longitude = action.payload.location.center.lng

            state.multiStepFormFields.fullAddress = fullAddress
            state.multiStepFormFields.latitude = parseFloat(latitude.toFixed(7))
            state.multiStepFormFields.longitude = parseFloat(longitude.toFixed(7))
        },
        clearError(state) {
            state.errorsFieldsMap = null
        },
    },
    extraReducers: builder => {
        builder
            .addCase(doSignUp.pending, state => {
                if (state.loading === RequestState.IDLE) {
                    state.loading = RequestState.PENDING
                }
            })
            .addCase(doSignUp.fulfilled, (state, action: any) => {
                state.loading = RequestState.SUCCESS
                state.multiStepFormFields = initialState.multiStepFormFields
                LocalStorage.remove(LocalStorageName.SIGNUP_NAME)
                LocalStorage.remove(LocalStorageName.SIGNUP_EMAIL)
                LocalStorage.remove(LocalStorageName.SIGNUP_CATEGORY_ID)
                LocalStorage.remove(LocalStorageName.SIGNUP_BUSINESS_NAME)
            })
            .addCase(doSignUp.rejected, (state, action: any) => {
                if (state.loading === RequestState.PENDING) {
                    state.loading = RequestState.IDLE
                    if (action.payload.details?.body) {
                        const errors = action.payload.details.body
                        state.errorsFieldsMap = getErrorsMap(errors)
                    }
                }
            })
    },
})

export const { updateSignupFieldsStep1, updateSignupFieldsStep2, updateSignupFieldsStep3, clearError } =
    signupSlice.actions

export default signupSlice.reducer

// Listeners middleware

// После успешной регистрации обновляем пользователя и получаем список сетей для него
startAppListening({
    type: 'auth/signup/fulfilled',
    effect: async (action: any, { dispatch }) => {
        if (action.payload) {
            dispatch(updateUser(action.payload))
            dispatch(doNetworksLoad())
        }
    },
})
