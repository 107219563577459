import React from 'react'
import cn from 'classnames'
import { toast, Slide } from 'react-toastify'
import styles from './styles.module.sass'
import { ReactComponent as CloseIcon } from '@Icons/close-toast.svg'

export const TOAST_TYPES = toast.TYPE

const Notification = ({ message, title, count }) => {
    return (
        <div className={styles.lkNotification}>
            {(title === undefined || title) && (
                <span
                    className={cn(styles.lkNotification__title, { [styles.lkNotification__title_withCounter]: count })}
                >
                    <span>{title ? title : 'Notification'}</span>
                    {count > 1 && <div className={styles.lkNotification__counter}>{count}</div>}
                </span>
            )}
            <span className={styles.lkNotification__text} dangerouslySetInnerHTML={{ __html: message }} />
        </div>
    )
}

const NotificationClsBtn = props => {
    const { onCloseClick, closeToast } = props
    let handleClose = closeToast

    if (onCloseClick) {
        handleClose = onCloseClick
    }

    return (
        <button className={styles.lkNotificationClsBtn} onClick={handleClose}>
            <CloseIcon />
        </button>
    )
}

const getToastClassname = type => {
    switch (type) {
    case TOAST_TYPES.ERROR:
        return styles.lkNotificationsContainerError
    case TOAST_TYPES.SUCCESS:
        return styles.lkNotificationsContainerSuccess
    case TOAST_TYPES.WARNING:
        return styles.lkNotificationsContainerWarning
    case TOAST_TYPES.INFO:
        return styles.lkNotificationsContainerInfo
    default:
        return styles.lkNotificationsContainerDefault
    }
}

export function createToast(message, title, options = {}) {
    const { onCloseClick, count, ...restOptions } = options
    const toastDefaultOptions = {
        className: cn(styles.lkNotificationWrapper, styles.lkNotificationWrapper, getToastClassname(options.type)),
        bodyClassName: styles.lkNotification,
        autoClose: 5000,
        draggable: true,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_LEFT,
        transition: Slide,
        closeButton: undefined ? <NotificationClsBtn onCloseClick={onCloseClick} /> : false,
    }

    toast(<Notification message={message} title={title || null} count={count} />, {
        ...toastDefaultOptions,
        ...restOptions,
    })
}

export function hideToast(toastId) {
    if (toast.isActive(toastId)) {
        toast.dismiss(toastId)
    }
}
