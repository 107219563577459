import React from 'react'
import styles from './styles.module.sass'
import { ReactComponent as LaikaLogoSvg } from '@Images/laika-logo.svg'

export default function LaikaLogo(): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.authLogo__pointerLogoWrap}>
                <LaikaLogoSvg />
            </div>
        </React.Fragment>
    )
}
