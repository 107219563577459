import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { startAppListening } from '@Store/middleware'

// Utils
import request from '@Utils/request'
import { getErrorsMap } from '@Utils/errors'

// Types
import {
    IntegrationsState,
    RequestIntegrationsLoad,
    RequestTripadvisorConnect,
    RequestIntegrationRemove,
    RequestGoogleConnect,
    Integration,
} from '@Types/store/integrations'
import { RequestState } from '@Types/enums/requestState'
import { LoadingDataState } from '@Types/enums/loadingDataState'

export const loadIntegrations = createAsyncThunk(
    'integrations/load',
    async (params: RequestIntegrationsLoad, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/integrations`,
                method: 'GET',
            })

            return response.data as AxiosResponse<{ reviews: Integration[] }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const doTripadvisorConnect = createAsyncThunk(
    'integrations/tripadvisor-connect',
    async (params: RequestTripadvisorConnect, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/integrate-tripadvisor`,
                method: 'POST',
                data: params.credentials,
            })

            return response.data as AxiosResponse<{}>
        } catch (err: any) {
            if (!err?.response) {
                throw err
            }

            return rejectWithValue(err.response.data)
        }
    }
)

export const doGoogleConnect = createAsyncThunk(
    'integrations/google-connect',
    async (params: RequestGoogleConnect, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/integrate-google-simple`,
                method: 'POST',
                data: params.credentials,
            })

            return response.data as AxiosResponse<{}>
        } catch (err: any) {
            if (!err?.response) {
                throw err
            }

            return rejectWithValue(err.response.data)
        }
    }
)

export const doRemoveConnect = createAsyncThunk(
    'integrations/remove',
    async (params: RequestIntegrationRemove, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/integrations/${params.integrationUuid}`,
                method: 'DELETE',
            })

            return response.data as AxiosResponse<{}>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const initialState = {
    loading: LoadingDataState.IDLE,
    tripadvisorConnectLoading: RequestState.IDLE,
    googleConnectLoading: RequestState.IDLE,
    removeLoading: RequestState.IDLE,
    integrations: [],
    errorsTripadvisorConnectFieldsMap: null,
    errorsGoogleConnectFieldsMap: null,
} as IntegrationsState

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadIntegrations.pending, state => {
                if (state.loading === LoadingDataState.IDLE) {
                    state.loading = LoadingDataState.LOADING
                }
            })
            .addCase(loadIntegrations.fulfilled, (state, action: any) => {
                state.loading = LoadingDataState.LOADED
                state.integrations = action.payload.integrations
            })
            .addCase(loadIntegrations.rejected, state => {
                if (state.loading === LoadingDataState.LOADING) {
                    state.loading = LoadingDataState.ERROR
                }
            })

            .addCase(doTripadvisorConnect.pending, state => {
                if (state.tripadvisorConnectLoading === RequestState.IDLE) {
                    state.tripadvisorConnectLoading = RequestState.PENDING
                }
            })
            .addCase(doTripadvisorConnect.fulfilled, (state, action: any) => {
                state.tripadvisorConnectLoading = RequestState.SUCCESS
            })
            .addCase(doTripadvisorConnect.rejected, (state, action: any) => {
                if (state.tripadvisorConnectLoading === RequestState.PENDING) {
                    state.tripadvisorConnectLoading = RequestState.IDLE
                    if (action.payload.details?.body) {
                        const errors = action.payload.details.body
                        state.errorsTripadvisorConnectFieldsMap = getErrorsMap(errors)
                    }
                }
            })

            .addCase(doGoogleConnect.pending, state => {
                if (state.googleConnectLoading === RequestState.IDLE) {
                    state.googleConnectLoading = RequestState.PENDING
                }
            })
            .addCase(doGoogleConnect.fulfilled, (state, action: any) => {
                state.googleConnectLoading = RequestState.SUCCESS
            })
            .addCase(doGoogleConnect.rejected, (state, action: any) => {
                if (state.googleConnectLoading === RequestState.PENDING) {
                    state.googleConnectLoading = RequestState.IDLE
                    if (action.payload.details?.body) {
                        const errors = action.payload.details.body
                        state.errorsGoogleConnectFieldsMap = getErrorsMap(errors)
                    }
                }
            })

            .addCase(doRemoveConnect.pending, state => {
                if (state.removeLoading === RequestState.IDLE) {
                    state.removeLoading = RequestState.PENDING
                }
            })
            .addCase(doRemoveConnect.fulfilled, (state, action: any) => {
                state.removeLoading = RequestState.SUCCESS
            })
            .addCase(doRemoveConnect.rejected, state => {
                if (state.removeLoading === RequestState.PENDING) {
                    state.removeLoading = RequestState.IDLE
                }
            })
    },
})

export default integrationsSlice.reducer

// Listeners middleware

// После успешного подключения платформы обновляем их
startAppListening({
    type: 'integrations/tripadvisor-connect/fulfilled',
    effect: async (action: any, { dispatch }) => {
        if (action.meta.arg.networkUuid) {
            dispatch(loadIntegrations({ networkUuid: action.meta.arg.networkUuid }))
        }
    },
})

startAppListening({
    type: 'integrations/google-connect/fulfilled',
    effect: async (action: any, { dispatch }) => {
        if (action.meta.arg.networkUuid) {
            dispatch(loadIntegrations({ networkUuid: action.meta.arg.networkUuid }))
        }
    },
})

// После успешного удаления интеграции нужно обновить список интеграций
startAppListening({
    type: 'integrations/remove/fulfilled',
    effect: async (action: any, { dispatch }) => {
        if (action.meta.arg.networkUuid) {
            dispatch(loadIntegrations({ networkUuid: action.meta.arg.networkUuid }))
        }
    },
})
