import React, { useMemo } from 'react'

// Redux
import { useSelector } from 'react-redux'

// Styles
import cn from 'classnames'
import commonStyles from '@Styles/common.module.sass'

// Icons and images
import { ReactComponent as BurgerIcon } from '@Icons/burger.svg'

// Types
import { RootState } from '@Store/store'

type Props = {
    title: string
    onClickMenuToggle: () => void
    hideTitle: boolean
}

export default function Header({ title, onClickMenuToggle, hideTitle }: Props): JSX.Element {
    const { currentNetwork } = useSelector((state: RootState) => state.networks)

    const logoUrl = useMemo(() => {
        return currentNetwork?.uploads[0]?.data.image.original.url
    }, [currentNetwork])

    const networkName = useMemo(() => {
        return currentNetwork?.name || ''
    }, [currentNetwork])

    return (
        <header className={commonStyles.header}>
            <div className={commonStyles.header__inner}>
                <div className={commonStyles.header__burgerButton}>
                    <button onClick={onClickMenuToggle}>
                        <BurgerIcon />
                    </button>
                </div>
                {title && (
                    <h1
                        className={cn(commonStyles.header__title, {
                            [commonStyles.header__title_desktopHide]: hideTitle,
                        })}
                    >
                        {title}
                    </h1>
                )}
                <div className={commonStyles.header__profile}>
                    <div
                        className={cn(commonStyles.header__profile__logo, {
                            [commonStyles.header__profile__logo_firstChartLogo]: !logoUrl,
                        })}
                    >
                        {logoUrl ? <img src={logoUrl} alt="Logo" /> : Array.from(networkName)[0]}
                    </div>
                </div>
            </div>
        </header>
    )
}
