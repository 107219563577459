import React from 'react'

// Libs
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css' // optional

// Styles
import cn from 'classnames'
import commonBStyles from '@Styles/UiKit/Buttons/common.module.sass'

export type Props = {
    id?: string
    name: string
    onClick: () => void
    classNameWrapper?: string
    classNameButton?: string
    disabled?: boolean
    tooltip?: React.ReactElement | string
    iconRight?: React.ReactElement | null
    type?: 'button' | 'submit' | 'reset'
    hideTooltip?: boolean
}

export default function BigButton(props: Props): React.ReactElement {
    const { id, onClick, classNameButton, name, disabled, tooltip, classNameWrapper, iconRight, type, hideTooltip } =
        props

    return (
        <Tippy content={tooltip} placement="bottom" disabled={!tooltip || hideTooltip}>
            <div className={classNameWrapper}>
                <button
                    id={id}
                    className={cn(classNameButton, commonBStyles.bigButton, commonBStyles.bigButton_green)}
                    onClick={onClick}
                    disabled={disabled}
                    type={type}
                >
                    <span>{name}</span>
                    {iconRight && <i>{iconRight}</i>}
                </button>
            </div>
        </Tippy>
    )
}
