import React, { useEffect } from 'react'
import Routes from '@Config/routes'
import { IntercomProvider } from 'react-use-intercom'

// Redux
import { useDispatch } from 'react-redux'
import { doAppInit } from '@Slices/appSlice'
import { AppDispatch } from '@Store/store'

// Utils
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Styles
import commonStyles from '@Styles/common.module.sass'

const INTERCOM_APP_ID = 'wjs7b0b3'

export default function App() {
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        dispatch(doAppInit())
    })

    return (
        <React.Fragment>
            <IntercomProvider autoBoot appId={INTERCOM_APP_ID}>
                <ToastContainer className={commonStyles.toastContainer} />
                <Routes />
            </IntercomProvider>
        </React.Fragment>
    )
}
