import React, { useCallback, useMemo } from 'react'

// Styles
import cn from 'classnames'
import styles from '@Styles/UiKit/Selects/selects.module.sass'

// Libs
import { useOuterClick } from '@Hooks/useOuterClick'

// Hooks
import { ScrollBar } from '@Utils/scroll'

// Components
import Option from './Option'

// Types
import { CommonOptionsType } from '@Types/common'

type Props = {
    options: CommonOptionsType[]
    onChange: (value: string | number) => void
    label?: string
    placeholder: string
    value: string | number | null
    classNameWrapper?: string
    isError?: boolean
    disabled?: boolean
    emptyOptionsMessage?: string
    slideUp?: boolean
}

export default function SelectWithSearch({
    options,
    value,
    onChange,
    classNameWrapper,
    label,
    placeholder,
    isError,
    disabled,
    emptyOptionsMessage,
    slideUp,
}: Props): JSX.Element {
    const [isOpen, toggle] = React.useState<boolean>(false)
    const dropdownRef = React.useRef(null)
    useOuterClick(dropdownRef, toggle.bind(null, false))

    const handleSelectorClick = useCallback(() => {
        if (!disabled) {
            toggle(!isOpen)
        }
    }, [isOpen, disabled])

    const handleOptionClick = useCallback(
        (value: string | number) => {
            onChange(value)
            toggle(false)
        },
        [onChange]
    )

    const optionsMap = useMemo(() => {
        return new Map(options.map(option => [option.value, option]))
    }, [options])

    const selectValue = useMemo(() => {
        const option = value ? optionsMap.get(value) : null

        return option ? option?.label : null
    }, [optionsMap, value])

    return (
        <div className={cn(styles.signUpInputGroup, styles.signUpSelectInputGroup)}>
            {label && (
                <label className={styles.label}>
                    <span>{label}</span>
                </label>
            )}
            <div ref={dropdownRef} className={styles.selectWrapper}>
                <div
                    className={cn(styles.selectInputWrap, {
                        [styles.selectInputWrap_opened]: isOpen,
                    })}
                    onClick={handleSelectorClick}
                >
                    <input
                        className={cn(styles.selectInput, {
                            [styles.signUpSelectInput_isError]: isError,
                            [styles.signUpSelectInput_placeholder]: placeholder,
                            [styles.signUpSelectInput_opened]: isOpen,
                        })}
                        type={'text'}
                        value={selectValue || placeholder || ''}
                        disabled={disabled}
                        readOnly
                    />
                </div>
                {!disabled && (
                    <div
                        className={cn(styles.selectDropdown, {
                            [styles.selectDropdown_visible]: isOpen,
                            [styles.selectDropdown_slideUp]: slideUp,
                        })}
                    >
                        <div
                            className={cn(styles.selectDropdown__optionsList, {
                                [styles.selectDropdown__optionsList_emptyOptions]: options.length === 0,
                            })}
                        >
                            <ScrollBar style={{ height: '194px' }}>
                                <div className={styles.selectDropdown__optionsWrap}>
                                    {options?.length === 0 && (
                                        <span className={styles.notFound}>{emptyOptionsMessage}</span>
                                    )}
                                    {options?.map((option: CommonOptionsType) => (
                                        <Option
                                            value={option.value}
                                            key={option.value}
                                            isSelected={value === option.value}
                                            label={option?.label || ''}
                                            onChange={handleOptionClick}
                                        />
                                    ))}
                                </div>
                            </ScrollBar>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
