import React from 'react'

// Styles
import cn from 'classnames'
import styles from './styles.module.sass'

// Icons
import LaikaLogo from '@Components/Common/Logos/LaikaLogo/LaikaLogo'

export default function LaikaFooterLogo(): JSX.Element {
    return (
        <div className={cn(styles.laikaFooterLogo)}>
            <LaikaLogo />
        </div>
    )
}
