import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

// Styles
import styles from './styles.module.sass'
import cn from 'classnames'

// Icons
import { ReactComponent as UnionLogo } from '@Icons/union.svg'

// Components
import BigButton from '../Common/Buttons/BigButton/BigButton'

// Constants
import * as Paths from '@Constants/paths'

export default function Error500() {
    const handleRefreshPageClick = useCallback(() => {
        window.location.reload()
    }, [])

    return (
        <div className={cn(styles.errorPageMessage, styles.errorPageMessage__404)}>
            <div className={styles.errorPageMessage__containerImage}>
                <UnionLogo width={36} />
            </div>
            <h2 className={styles.errorPageMessage__title}>Internal server error</h2>
            <div className={styles.errorPageMessage__descriptionText}>
                We&rsquo;re working on&nbsp;fixing the problem. Please try again.
            </div>
            <BigButton
                classNameWrapper={styles.errorPageMessage__buttonWrapper}
                name="Refresh page"
                onClick={handleRefreshPageClick}
            />
            <Link to={Paths.DASHBOARD_MAIN_PATH} className={styles.errorPageMessage__link}>
                Go to home
            </Link>
        </div>
    )
}
