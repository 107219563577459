import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'

import cn from 'classnames'

type ScrollbarProps = {
    options?: any
    children: React.ReactNode
    style?: React.CSSProperties
    className?: string
}

export const ScrollBar: React.FC<ScrollbarProps> = ({ children, className, options, style, ...props }) => {
    return (
        <OverlayScrollbarsComponent
            options={{
                className: cn('os-theme-thin', className),
                scrollbars: {
                    autoHide: 'move',
                },
                ...options,
            }}
            style={style}
            {...props}
        >
            {children}
        </OverlayScrollbarsComponent>
    )
}
