import React, { useCallback, useMemo } from 'react'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { doUploadNetworkLogo } from '@Slices/networksSlice'
import { AppDispatch } from '@Store/store'

// Libs
import { useOuterClick } from '@Hooks/useOuterClick'

// Styles
import cn from 'classnames'
import styles from './styles.module.sass'

// Components
import ProfileDropdown from './components/ProfileDropdown/ProfileDropdown'
import { ReactComponent as UploadIcon } from '@Icons/upload.svg'

// Images and icons
import { ReactComponent as ArrowUpDownIcon } from '@Icons/arrow-up-down.svg'
import { ReactComponent as ArrowUpDownIconOpen } from '@Icons/arrow-up-down-open.svg'

// Types
import { RootState } from '@Store/store'

export default function ProfileBlock(): JSX.Element {
    const { currentNetwork } = useSelector((state: RootState) => state.networks)
    const dispatch = useDispatch<AppDispatch>()

    const logoUrl = useMemo(() => {
        return currentNetwork?.uploads[0]?.data.image.original.url
    }, [currentNetwork])

    const networkName = useMemo(() => {
        return currentNetwork?.name || ''
    }, [currentNetwork])

    const [isOpen, toggle] = React.useState<boolean>(false)
    const dropdownRef = React.useRef(null)
    useOuterClick(dropdownRef, toggle.bind(null, false)) //close dropdown on outside click

    const handleSelectorClick = useCallback(() => {
        toggle(!isOpen)
    }, [isOpen])

    const handleChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            const data = new FormData()
            data.append('file', file, file.name)
            dispatch(doUploadNetworkLogo(data))
        },
        [dispatch]
    )

    return (
        <div ref={dropdownRef} className={styles.profileBlockWrapper}>
            <div className={styles.profileBlock} onClick={handleSelectorClick}>
                <div className={cn(styles.profileBlock__button, { [styles.profileBlock__button_isOpen]: isOpen })}>
                    <div
                        className={cn(styles.profileBlock__logo, {
                            [styles.profileBlock__logo_firstChartLogo]: !logoUrl,
                        })}
                    >
                        {logoUrl ? <img src={logoUrl} alt="Logo" /> : Array.from(networkName)[0]}
                        <div className={styles.profileBlock__logo__filter}></div>
                        <div className={styles.profileBlock__logo__uploadIconWrapper}>
                            <UploadIcon />
                        </div>
                        <input
                            id="fileElem"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleChange}
                            title=""
                        />
                    </div>
                    <div className={styles.profileBlock__infoWrapper}>
                        <div className={styles.profileBlock__name}>{networkName}</div>
                        <div className={styles.profileBlock__tariff}>30 trial days left</div>
                    </div>
                    <div className={styles.profileBlock__buttonWrap}>
                        <div className={styles.profileBlock__buttonIcon}>
                            {isOpen ? <ArrowUpDownIconOpen /> : <ArrowUpDownIcon />}
                        </div>
                    </div>
                </div>
            </div>
            <div className={cn(styles.profileDropdownWrapper, { [styles.profileDropdownWrapper_visible]: isOpen })}>
                <div className={styles.profileDropdown}>
                    <ProfileDropdown />
                </div>
            </div>
        </div>
    )
}
