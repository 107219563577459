import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { startAppListening } from '@Store/middleware'

// Utils
import request from '@Utils/request'
import { getErrorsMap } from '@Utils/errors'

// Stores
import { doNetworksLoad } from './networksSlice'

// Types
import {
    RootUserObject,
    UsersState,
    SignUpRequestParams,
    RestorePasswordRequestParams,
    SetPasswordRequestParams,
    RequestResetPasswordTokeLoad,
} from '@Types/store/user'

import { RootState } from '@Store/store'
import { RequestState } from '@Types/enums/requestState'
import { UserStatus } from '@Types/enums/userStatus'
import { LoadingDataState } from '@Types/enums/loadingDataState'

export const doUserSignIn = createAsyncThunk(
    'user/signIn',
    async (data: SignUpRequestParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await request({ url: '/auth/login', method: 'POST', data, cType: true })

            return response.data as AxiosResponse<RootUserObject>
        } catch (err: any) {
            if (!err?.response) {
                throw err
            }

            return rejectWithValue(err.response.data)
        }
    }
)

export const doUserLoadMe = createAsyncThunk('user/load', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await request({ url: '/auth/me', method: 'GET' })
        const data = response.data as AxiosResponse<RootUserObject>

        return data
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const doUserLogout = createAsyncThunk('user/logout', async (_, { rejectWithValue }) => {
    try {
        const response = await request({ url: '/auth/logout', method: 'post' })
        return response.data as AxiosResponse<RootUserObject>
    } catch (err: any) {
        if (!err?.response) {
            throw err
        }

        return rejectWithValue(err.response.data)
    }
})

export const doUserRestorePassword = createAsyncThunk(
    'user/resetPassword',
    async (data: RestorePasswordRequestParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await request({ url: '/auth/reset-password', method: 'POST', data })

            return response.data as AxiosResponse<RootUserObject>
        } catch (err: any) {
            if (!err?.response) {
                throw err
            }

            return rejectWithValue(err.response.data)
        }
    }
)

export const doUserRestoreTokenLoad = createAsyncThunk(
    'user/getRestoreToken',
    async (params: RequestResetPasswordTokeLoad, { dispatch, rejectWithValue }) => {
        try {
            const response = await request({ url: '/auth/reset-password', method: 'GET', params })
            const data = response.data as AxiosResponse<RootUserObject>

            return data
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const doUserRestoreSetPassword = createAsyncThunk(
    'user/setPassword',
    async (data: SetPasswordRequestParams, { dispatch, rejectWithValue, getState }) => {
        const { user } = getState() as RootState
        try {
            const response = await request({
                url: '/auth/reset-password',
                method: 'PUT',
                params: { t: user.resetPasswordToken || '' },
                data,
            })

            return response.data as AxiosResponse<RootUserObject>
        } catch (err: any) {
            if (!err?.response) {
                throw err
            }

            return rejectWithValue(err.response.data)
        }
    }
)

const initialState = {
    me: null,
    resetPasswordToken: null,
    signInLoading: LoadingDataState.IDLE,
    meLoading: LoadingDataState.IDLE,
    logoutLoading: RequestState.IDLE,
    restoreLoading: RequestState.IDLE,
    restoreTokenLoading: LoadingDataState.IDLE,
    setPasswordLoading: RequestState.IDLE,
    errorsFieldsMap: null,
    status: UserStatus.loading,
    resetPasswordError: null,
} as UsersState

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser(state, action) {
            state.me = action.payload.user
            state.status = UserStatus.loggedIn
        },
        doUserRestoreClearError(state) {
            state.resetPasswordError = null
        },
    },
    extraReducers: builder => {
        builder
            .addCase(doUserSignIn.pending, state => {
                state.signInLoading = LoadingDataState.LOADING
            })
            .addCase(doUserSignIn.fulfilled, (state, action: any) => {
                state.me = action.payload.user
                state.signInLoading = LoadingDataState.LOADED
                state.status = UserStatus.loggedIn
            })
            .addCase(doUserSignIn.rejected, (state, action: any) => {
                if (state.signInLoading === LoadingDataState.LOADING) {
                    state.signInLoading = LoadingDataState.ERROR
                    state.status = UserStatus.loggedOut
                    if (action.payload.details?.body) {
                        const errors = action.payload.details.body
                        state.errorsFieldsMap = getErrorsMap(errors)
                    }
                }
            })

            .addCase(doUserLoadMe.pending, state => {
                state.meLoading = LoadingDataState.LOADING
            })
            .addCase(doUserLoadMe.fulfilled, (state, action: any) => {
                state.me = action.payload.user
                state.status = UserStatus.loggedIn
                state.meLoading = LoadingDataState.LOADED
            })
            .addCase(doUserLoadMe.rejected, state => {
                if (state.meLoading === LoadingDataState.LOADING) {
                    state.status = UserStatus.loggedOut
                    state.meLoading = LoadingDataState.ERROR
                }
            })

            .addCase(doUserLogout.pending, state => {
                state.logoutLoading = RequestState.PENDING
            })
            .addCase(doUserLogout.fulfilled, state => {
                state.me = null
                state.status = UserStatus.loggedOut
                state.logoutLoading = RequestState.SUCCESS
            })
            .addCase(doUserLogout.rejected, (state, action: any) => {
                if (state.logoutLoading === RequestState.PENDING) {
                    // TODO - if (action.payload?.error?.message === JWT_EXPIRED_MESSAGES) {
                    if (action) {
                        state.status = UserStatus.loggedOut
                    }
                    state.logoutLoading = RequestState.IDLE
                }
            })

            .addCase(doUserRestorePassword.pending, state => {
                if (state.restoreLoading === RequestState.IDLE) {
                    state.restoreLoading = RequestState.PENDING
                }
            })
            .addCase(doUserRestorePassword.fulfilled, state => {
                state.restoreLoading = RequestState.SUCCESS
            })
            .addCase(doUserRestorePassword.rejected, (state, action: any) => {
                if (state.restoreLoading === RequestState.PENDING) {
                    state.restoreLoading = RequestState.IDLE
                    if (action.payload.details?.body) {
                        const errors = action.payload.details.body
                        state.errorsFieldsMap = getErrorsMap(errors)
                    }
                }
            })

            .addCase(doUserRestoreTokenLoad.pending, state => {
                if (state.restoreTokenLoading === LoadingDataState.IDLE) {
                    state.restoreTokenLoading = LoadingDataState.LOADING
                }
            })
            .addCase(doUserRestoreTokenLoad.fulfilled, (state, action: any) => {
                state.restoreTokenLoading = LoadingDataState.LOADED
                if (action.meta.arg.t) {
                    state.resetPasswordToken = action.meta.arg.t
                }
            })
            .addCase(doUserRestoreTokenLoad.rejected, (state, action: any) => {
                if (state.restoreTokenLoading === LoadingDataState.LOADING) {
                    state.restoreTokenLoading = LoadingDataState.ERROR
                    if (action.payload.response?.statusText) {
                        state.resetPasswordError = action.payload.response.statusText
                    }
                }
            })

            .addCase(doUserRestoreSetPassword.pending, state => {
                if (state.setPasswordLoading === RequestState.IDLE) {
                    state.setPasswordLoading = RequestState.PENDING
                }
            })
            .addCase(doUserRestoreSetPassword.fulfilled, state => {
                state.setPasswordLoading = RequestState.SUCCESS
                state.resetPasswordToken = null
            })
            .addCase(doUserRestoreSetPassword.rejected, (state, action: any) => {
                if (state.setPasswordLoading === RequestState.PENDING) {
                    state.setPasswordLoading = RequestState.IDLE
                    if (action.payload.details?.body) {
                        const errors = action.payload.details.body
                        state.errorsFieldsMap = getErrorsMap(errors)
                    }
                }
            })
    },
})

export const { updateUser, doUserRestoreClearError } = userSlice.actions

export default userSlice.reducer

// Listeners middleware

// После успешной загрузки пользователя - получаем список сетей
// происходит это после инициализации приложения которая происходит
// либо когда jwt токен есть и пользователь сразу успешно загружается
// либо после авторизации, запускаем doAppInit для загрузки пользователя и тд.
startAppListening({
    type: 'user/load/fulfilled',
    effect: async (action: any, { dispatch }) => {
        if (action.payload.user) {
            dispatch(doNetworksLoad())
        }
    },
})
