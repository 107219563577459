import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import request from '@Utils/request'
import { startAppListening } from '@Store/middleware'

import { concatUniqueByUuid } from '@Utils/redux'

// Types
import {
    ReviewRequestsState,
    RequestReviewRequestsLoad,
    RequestReviewRequestsCountsLoad,
    ReviewRequestsResponseCounts,
} from '@Types/store/reviewRequests'

import { RequestState } from '@Types/enums/requestState'
import { LoadingDataState } from '@Types/enums/loadingDataState'

export const loadReviewRequests = createAsyncThunk(
    'reviewRequests/load',
    async (params: RequestReviewRequestsLoad, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/review-inquiries`,
                method: 'GET',
                params: params.filterParams,
            })

            return response.data as AxiosResponse<{ reviews: any[] }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const loadReviewRequestsCounts = createAsyncThunk(
    'reviewRequests/counts',
    async (params: RequestReviewRequestsCountsLoad, { rejectWithValue }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/review-inquiry-full-counts-data`,
                method: 'GET',
                params: params.filterParams,
            })

            return response.data as AxiosResponse<{ reviewInquiryFullCountsData: ReviewRequestsResponseCounts }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const doReviewRequest = createAsyncThunk(
    'reviewRequests/send',
    async (params: { networkUuid: string; filterParams: { name: string; email: string } }, { rejectWithValue }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/review-inquiries`,
                method: 'POST',
                data: params.filterParams,
                cType: true,
            })

            return response.data as AxiosResponse<any>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const initialState = {
    loading: LoadingDataState.IDLE,
    loadingCounts: LoadingDataState.IDLE,
    addLoading: RequestState.IDLE,
    requests: [],
    counts: null,
    firstLoading: true,
} as ReviewRequestsState

const reviewRequestsSlice = createSlice({
    name: 'reviewRequest',
    initialState,
    reducers: {
        updateReviewRequests(state, action) {
            state.requests = action.payload.updateReviewRequests || []
        },
        clearLoadingState(state) {
            state.loading = LoadingDataState.IDLE
            state.firstLoading = true
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadReviewRequests.pending, state => {
                if (state.loading !== LoadingDataState.IDLE) {
                    state.firstLoading = false
                }
                state.loading = LoadingDataState.LOADING
            })
            .addCase(loadReviewRequests.fulfilled, state => {
                state.loading = LoadingDataState.LOADED
                // Обновляем через мидлвару
            })
            .addCase(loadReviewRequests.rejected, state => {
                if (state.loading === LoadingDataState.LOADING) {
                    state.loading = LoadingDataState.ERROR
                }
            })

            .addCase(loadReviewRequestsCounts.pending, state => {
                state.loadingCounts = LoadingDataState.LOADING
            })
            .addCase(loadReviewRequestsCounts.fulfilled, (state, action: any) => {
                state.loadingCounts = LoadingDataState.LOADED
                state.counts = action.payload.reviewInquiryFullCountsData
            })
            .addCase(loadReviewRequestsCounts.rejected, state => {
                if (state.loadingCounts === LoadingDataState.LOADING) {
                    state.loadingCounts = LoadingDataState.ERROR
                }
            })

            .addCase(doReviewRequest.pending, state => {
                if (state.addLoading === RequestState.IDLE) {
                    state.addLoading = RequestState.PENDING
                }
            })
            .addCase(doReviewRequest.fulfilled, (state, action: any) => {
                state.addLoading = RequestState.SUCCESS
            })
            .addCase(doReviewRequest.rejected, state => {
                if (state.addLoading === RequestState.PENDING) {
                    state.addLoading = RequestState.IDLE
                }
            })
    },
})

export const { updateReviewRequests, clearLoadingState } = reviewRequestsSlice.actions

export default reviewRequestsSlice.reducer

// После загрузки отзывов, если это была загрузка loadMore то объединяем старые и новые отзывы
//в другом случае просто обновляем список на новый
startAppListening({
    type: 'reviewRequests/load/fulfilled',
    effect: async (action: any, { dispatch, getState }) => {
        const newReviewRequests = action.payload.reviewInquiries
        const loadMore = action.meta.arg.loadMore

        if (loadMore) {
            const { reviewRequests } = getState()
            dispatch(
                updateReviewRequests({
                    updateReviewRequests: concatUniqueByUuid(reviewRequests.requests, newReviewRequests),
                })
            )
        } else {
            dispatch(updateReviewRequests({ updateReviewRequests: newReviewRequests }))
        }
    },
})
