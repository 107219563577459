export function getErrorsMap(errors: any) {
    const resultMap = new Map()
    const keys = Object.keys(errors)

    if (keys?.length > 0) {
        keys.forEach((key: string) => {
            if (typeof errors[key] === 'string') {
                if (resultMap.has(key)) {
                    const availableError = resultMap.set(key, errors)
                    resultMap.set(key, [availableError, errors].join(', '))
                } else {
                    resultMap.set(key, errors)
                }
            } else if (typeof errors[key] === 'object') {
                const fieldErrors = errors[key]
                if (resultMap.has(key)) {
                    const availableError = resultMap.set(key, errors)
                    resultMap.set(key, [availableError, errors.join(', ')].join(', '))
                } else {
                    resultMap.set(key, fieldErrors.join(', '))
                }
            }
        })
    }

    return resultMap
}
