export function getAppUrl() {
    if (process.env.REACT_APP_API === 'production') {
        return 'https://my.laika.live'
    }

    return 'http://localhost:3001'
}

export function getApiRoot() {
    if (process.env.REACT_APP_API === 'production') {
        return 'https://my.laika.live/api/client/v1'
    }

    return 'https://mydev.laika.live/api/client/v1'
}

export const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1Ijoia290Y2h1cHJpayIsImEiOiJjbGF5YmQ1dmYweWlkM3BtcHprb3l3MWthIn0.nhI_Vy7HrgyP4Zne9kr6oA'

export const GOOGLE_API_KEY = 'AIzaSyDhUDcVwsW4I2pKkTCRgczmUVMeBj88g-s'

export const config = {
    API_ROOT: getApiRoot(),
    APP_URL: getAppUrl(),
    PUBLIC_PATH: getAppUrl(),
    IS_DEV_APP: process.env.REACT_APP_API === 'development',
    IS_LOCAL_APP: process.env.REACT_APP_API === 'local',
}
