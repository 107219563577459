import { memo } from 'react'

import styles from './styles.module.sass'

import AnimationLoader from '@Components/Common/Icons/AnimationLoader/AnimationLoader'

export default memo(() => {
    return (
        <div className={styles.loaderWrapper}>
            <AnimationLoader />
        </div>
    )
})
