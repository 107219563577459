import { createElement } from 'react'
import { Navigate, RouteObject, matchPath, PathMatch } from 'react-router-dom'
import merge from 'lodash.merge'
import { isExternal } from '@Utils/validate'
import DashboardLayoutRoutes from '@Layouts/DashboardLayout/routes'

import {
    RouterType,
    PathKeyRouterType,
    RouterTypePathKeyRouter,
    BreadcrumbType,
    PathKeyRouteObject,
} from '@Types/router'

export const getItemRouteFromCurrentPathname = (
    currentPath: string
): { match: PathMatch; routeConfig: RouterType } | null => {
    let resultMatch = null

    DashboardLayoutRoutes.forEach(route => {
        const item = matchPath(route.path, currentPath)
        if (item) {
            resultMatch = { match: item, routeConfig: route }
        }
    })

    return resultMatch
}

export const getNetworkUuidFromCurrentPathname = (currentPath: string): string => {
    const dashboardMatching = getItemRouteFromCurrentPathname(currentPath)

    return dashboardMatching?.match?.params.networkUuid || ''
}

export const getReviewUuidFromCurrentPathname = (currentPath: string): string => {
    const dashboardMatching = getItemRouteFromCurrentPathname(currentPath)

    return dashboardMatching?.match?.params.reviewUuid || ''
}

export const createUseRoutes = (configRoutes: RouterType[], parentPath = '/'): RouteObject[] => {
    const routes: RouteObject[] = []
    for (let index = 0; index < configRoutes.length; index++) {
        const item = configRoutes[index]
        if (isExternal(item.path)) {
            // eslint-disable-next-line no-continue
            continue
        }
        const routesItem: RouteObject = {}

        // path
        routesItem.path = item.path.startsWith('/')
            ? item.path
            : `${parentPath.endsWith('/') ? parentPath : `${parentPath}/`}${item.path}`
        // element
        if (item.component) {
            routesItem.element = createElement(item.component)
        }
        // children
        const children: RouteObject[] = []
        if (item.redirect) {
            children.push({
                path: routesItem.path,
                element: createElement(Navigate, { to: item.redirect }),
            })
        }
        if (item.children) {
            children.push(...createUseRoutes(item.children, routesItem.path))
        }
        if (children.length > 0) {
            routesItem.children = children
        }

        // newItem push
        routes.push(routesItem)
    }

    return routes
}

/**
 * @param routes  RouteObject[]
 * @returns PathKeyRouteObject
 */
export const pathKeyCreateUseRoutes = (routes: RouteObject[]): PathKeyRouteObject => {
    let jsonItems: PathKeyRouteObject = {}
    for (let index = 0; index < routes.length; index++) {
        const item = routes[index]
        jsonItems[item.path || ''] = {
            ...item,
        }

        if (item.children) {
            jsonItems = merge(jsonItems, pathKeyCreateUseRoutes(item.children))
        }
    }
    return jsonItems
}

/**
 * @param routes RouterType[]
 * @param parentPath string
 * @param parentPaths string[]
 * @returns RouterTypePathKeyRouter
 */
export const formatRoutes = (
    routes: RouterType[],
    parentPath = '/',
    parentPaths: string[] = []
): RouterTypePathKeyRouter => {
    const items: RouterType[] = []
    let jsonItems: PathKeyRouterType = {}

    for (let index = 0; index < routes.length; index++) {
        const item = routes[index]
        const newItem: RouterType = {
            ...item,
        }

        let path = item.path || ''
        if (!isExternal(item.path)) {
            path = item.path.startsWith('/')
                ? item.path
                : `${parentPath.endsWith('/') ? parentPath : `${parentPath}/`}${item.path}`
        }
        newItem.path = path

        const meta = item.meta || {}
        const pPaths = meta.parentPath && meta.parentPath.length > 0 ? meta.parentPath : parentPaths
        meta.parentPath = pPaths
        newItem.meta = meta

        // children
        let children: RouterType[] | undefined
        let pkChildren: PathKeyRouterType | undefined
        if (item.children) {
            const fRoutes = formatRoutes(item.children, path, [...pPaths, path])

            children = fRoutes.router
            newItem.children = children

            pkChildren = fRoutes.pathKeyRouter
        }

        // item
        items.push(newItem)
        jsonItems[path] = newItem
        if (pkChildren) {
            jsonItems = merge(jsonItems, pkChildren)
        }
    }

    return {
        router: items,
        pathKeyRouter: jsonItems,
    }
}

/**
 * Определите, есть ли у текущего пользователя разрешения на основе имени пользовательского разрешения на входящую проверку подлинности
 * @param userRoles string[]
 * @param roles string | string[]
 * @returns boolean
 */
export const hasPermissionRoles = (userRoles: string[], roles?: string | string[]): boolean => {
    if (userRoles.length < 1) {
        return false
    }

    if (userRoles.includes('admin')) {
        return true
    }

    if (typeof roles === 'undefined') {
        return true
    }

    if (typeof roles === 'string') {
        return userRoles.includes(roles)
    }

    if (roles instanceof Array && roles.length === 0) {
        return true
    }

    if (roles instanceof Array && roles.length > 0) {
        return roles.some(role => userRoles.includes(role))
    }

    return false
}

/**
 * В соответствии с маршрутом pathname - возвращает соответствующий route массив
 * @param pathname string[]
 * @param jsonRoutesData PathKeyRouterType
 * @returns x[]
 */
export const getPathsTheRoutes = (pathname: string[], jsonRoutesData: PathKeyRouterType): RouterType[] => {
    const routeItem: RouterType[] = []

    for (let index = 0, len = pathname.length; index < len; index += 1) {
        const element = pathname[index]
        const item = jsonRoutesData[element] || {}
        if (item.path !== '') {
            routeItem.push(item)
        }
    }

    return routeItem
}

/**
 * Получаем массив, соответствующий хлебным крошкам
 * @param pathname string
 * @param jsonRoutesData  PathKeyRouterType
 * @returns BreadcrumbType[]
 */
export const getBreadcrumbRoutes = (pathname: string, jsonRoutesData: PathKeyRouterType): BreadcrumbType[] => {
    const route: RouterType = jsonRoutesData[pathname] || {}
    if (!route.path) {
        return []
    }

    if (!route.meta?.breadcrumb) {
        const parentPath = route.meta?.parentPath || []
        const routes = getPathsTheRoutes(parentPath, jsonRoutesData)
        const bread: BreadcrumbType[] = []

        for (let index = 0; index < routes.length; index++) {
            const element = routes[index]
            bread.push({
                title: element.meta?.title || '',
                path: element.path,
            })
        }

        if (route.meta?.breadcrumb === false) {
            return bread
        }

        bread.push({
            title: route.meta?.title || '',
            path: route.path,
        })

        return bread
    }

    return route.meta.breadcrumb
}
