import React, { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getItemRouteFromCurrentPathname } from '@Utils/router'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { changeCurrentNetwork } from '@Store/slices/networksSlice'

// Styles
import styles from './styles.module.sass'

// Components
import SelectNetworks from '@Components/Common/Selects/SelectNetworks/SelectNetworks'

// Constants
import * as Paths from '@Constants/paths'

// Types
import { RootState, AppDispatch } from '@Store/store'

export default function SidebarSelectNetworks(): JSX.Element {
    const navigate = useNavigate()
    const location = useLocation()

    const { networks, currentNetwork, networksMap } = useSelector((state: RootState) => state.networks)
    const dispatch = useDispatch<AppDispatch>()

    const dashboardMatching = getItemRouteFromCurrentPathname(location.pathname)
    const patternPath = dashboardMatching?.match.pattern.path || ''

    const handleNetworkChange = useCallback(
        (uuid: number | string) => {
            if (typeof uuid === 'string' && networksMap) {
                dispatch(changeCurrentNetwork({ network: networksMap.get(uuid) }))
                if (patternPath !== Paths.REVIEW_PATH) {
                    navigate(patternPath.replace(':networkUuid', uuid))
                } else {
                    navigate(Paths.DASHBOARD_PATH.replace(':networkUuid', uuid))
                }
            }
        },
        [dispatch, networksMap, navigate, patternPath]
    )

    const networkOptions = useMemo(() => {
        return networks?.length > 0
            ? networks.map((network: any) => ({ label: network.name, value: network.uuid }))
            : []
    }, [networks])

    return (
        <div className={styles.networksSelectInputGroup}>
            <SelectNetworks
                value={currentNetwork?.uuid || null}
                options={networkOptions}
                onChange={handleNetworkChange}
                placeholder=""
                emptyOptionsMessage="Networks not found"
                slideUp
            />
        </div>
    )
}
