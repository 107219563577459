import React, { lazy, memo, Suspense } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import { createUseRoutes, pathKeyCreateUseRoutes, getItemRouteFromCurrentPathname } from '@Utils/router'

import PageLoader from '@Components/Common/Loaders/PageLoader/PageLoader'

// BlankLayout
import BlankLayout from '@Layouts/BlankLayout'

// SecurityLayout
import SecurityLayout from '@Layouts/SecurityLayout'

// AuthLayout - шаблон страниц для формы авторизации и восстановления пароля
import AuthLayoutRoutes from '@Layouts/AuthLayout/routes'
import AuthLayout from '@Layouts/AuthLayout/AuthLayout'

// SignUpLayout - шаблон страниц для форм регистрации новых пользователей
import SignUpLayoutRoutes from '@Layouts/SignUpLayout/routes'
import SignUpLayout from '@Layouts/SignUpLayout/SignUpLayout'

// DashboardLayout - основной шаблон личного кабинета
import DashboardLayoutRoutes from '@Layouts/DashboardLayout/routes'
import DashboardLayout from '@Layouts/DashboardLayout/DashboardLayout'

// Constants
import * as Paths from '@Constants/paths'

export const configRootRoutes = [
    {
        path: '/',
    },
    {
        path: Paths.AUTH_PATH,
        redirect: Paths.LOGIN_PATH,
        children: AuthLayoutRoutes,
    },
    {
        path: Paths.SIGNUP_PATH,
        children: SignUpLayoutRoutes,
    },
    {
        path: Paths.DASHBOARD_MAIN_PATH,
        children: DashboardLayoutRoutes,
    },
    {
        path: '*',
        component: lazy(() => import('@Pages/404')),
    },
]

const routes = createUseRoutes(configRootRoutes)

const layoutToRoutes = {
    UniversalLayout: pathKeyCreateUseRoutes([routes[0]]),
    AuthLayout: pathKeyCreateUseRoutes([routes[1]]),
    SignUpLayout: pathKeyCreateUseRoutes([routes[2]]),
    DashboardPagesLayout: pathKeyCreateUseRoutes([routes[3]]),
    ListPagesLayout: pathKeyCreateUseRoutes([routes[4]]),
}

export const SuspenseLazy = memo(({ children }: { children: React.ReactNode }) => (
    <Suspense fallback={<PageLoader />}>{children}</Suspense>
))

export default memo(() => {
    const routesElement = useRoutes(routes)
    const location = useLocation()

    const dashboardMatching = getItemRouteFromCurrentPathname(location.pathname)

    // UniversalLayout
    if (layoutToRoutes.UniversalLayout[location.pathname]) {
        return (
            <SecurityLayout>
                <SuspenseLazy>{routesElement}</SuspenseLazy>
            </SecurityLayout>
        )
    }

    // AuthLayout
    if (layoutToRoutes.AuthLayout[location.pathname]) {
        return (
            <AuthLayout>
                <SuspenseLazy>{routesElement}</SuspenseLazy>
            </AuthLayout>
        )
    }

    // SignUpLayout
    if (layoutToRoutes.SignUpLayout[location.pathname]) {
        return (
            <SignUpLayout>
                <SuspenseLazy>{routesElement}</SuspenseLazy>
            </SignUpLayout>
        )
    }

    // DashboardLayout
    if (
        Boolean(dashboardMatching) ||
        layoutToRoutes.DashboardPagesLayout[location.pathname] ||
        location.pathname === '/dashboard' ||
        location.pathname === '/dashboard/'
    ) {
        return (
            <SecurityLayout>
                <DashboardLayout dashboardMatching={dashboardMatching}>
                    <SuspenseLazy>{routesElement}</SuspenseLazy>
                </DashboardLayout>
            </SecurityLayout>
        )
    }

    // UniversalLayout
    if (layoutToRoutes.ListPagesLayout[location.pathname]) {
        return (
            <SecurityLayout>
                <BlankLayout>
                    <SuspenseLazy>{routesElement}</SuspenseLazy>
                </BlankLayout>
            </SecurityLayout>
        )
    }

    // BlankLayout
    return (
        <BlankLayout>
            <SuspenseLazy>{routesElement}</SuspenseLazy>
        </BlankLayout>
    )
})
