import React from 'react'
import { PathMatch } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux'

// Styles
import styles from './styles.module.sass'
import commonStyles from '@Styles/common.module.sass'

// Components
import ProfileBlock from '@Components/ProfileBlock/ProfileBlock'
import MainSideNav from '@Components/SideNav/MainSideNav'
import SettingSideNav from '@Components/SideNav/SittingsSideNav'
import LaikaFooterLogo from '@Components/LaikaFooterLogo/LaikaFooterLogo'
import SidebarSelectNetworks from './components/SidebarSelectNetworks/SidebarSelectNetworks'

// Types
import { RootState } from '@Store/store'
import { RouterType } from '@Types/router'

type Props = {
    dashboardMatching: { match: PathMatch; routeConfig: RouterType } | null
    onMobileMenuToggle: () => void
}

export default function SideBar({ dashboardMatching, onMobileMenuToggle }: Props): JSX.Element {
    const user = useSelector((state: RootState) => state.user.me)

    return (
        <React.Fragment>
            <div className={styles.sidebarHeader}>
                <ProfileBlock />
            </div>
            <div className={commonStyles.sidebarSeparate} />
            <nav className={commonStyles.sidenav}>
                <div className={commonStyles.sidenavBody}>
                    <MainSideNav dashboardMatching={dashboardMatching} onMobileMenuToggle={onMobileMenuToggle} />
                    <div className={commonStyles.sidebarSeparate} />
                    <SettingSideNav dashboardMatching={dashboardMatching} onMobileMenuToggle={onMobileMenuToggle} />
                </div>
            </nav>
            <div className={commonStyles.footerSidebar}>
                {user?.isAdmin && <SidebarSelectNetworks />}
                <LaikaFooterLogo />
            </div>
        </React.Fragment>
    )
}
