import { SettingsType } from '@Types/settings.d'

const settings: SettingsType = {
    siteTitle: 'Laika',
    siteTokenKey: 'dashboard_token',
    ajaxHeadersTokenKey: 'x-token',
    ajaxResponseNoVerifyUrl: ['/auth/login', '/auth/me', '/auth/logout', '/auth/signup', '/auth/reset-password'],
}

export default settings
