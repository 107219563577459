import { memo, useMemo, useCallback, useState, useEffect } from 'react'
import { useNavigate, PathMatch } from 'react-router-dom'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentNetwork } from '@Store/slices/networksSlice'

// Styles
import cn from 'classnames'
import styles from './styles.module.sass'
import commonStyles from '@Styles/common.module.sass'

// Utils and hooks
import { formatRoutes } from '@Utils/router'
import useTitle from '@Hooks/useTitle'

// Config
import { configRootRoutes } from '@Config/routes'

// Components
import SideBar from '@Components/SideBar/SideBar'
import Header from '@Components/Header/Header'
import SidePop from '@Components/SidePop/SidePop'
import IconButton from '@Components/Common/Buttons/IconButton/IconButton'
import HeadTabs from '@Components/Common/Tabs/HeadTabs/HeadTabs'

// Constants
import * as Paths from '@Constants/paths'

// Types
import { RootState, AppDispatch } from '@Store/store'
import { RouterType } from '@Types/router'

type SignUpLayoutProps = {
    children: React.ReactNode
    dashboardMatching: { match: PathMatch; routeConfig: RouterType } | null
}

export default memo(({ children, dashboardMatching }: SignUpLayoutProps) => {
    const { currentNetwork, networksMap, networks } = useSelector((state: RootState) => state.networks)
    const dispatch = useDispatch<AppDispatch>()

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const navigate = useNavigate()

    const networkUuidParam = dashboardMatching?.match.params.networkUuid
    const patternPath = dashboardMatching?.match.pattern.path || ''

    const routerPathKeyRouter = useMemo(() => formatRoutes(configRootRoutes), [])

    const routeItem = useMemo(
        () => routerPathKeyRouter.pathKeyRouter[patternPath],
        [routerPathKeyRouter.pathKeyRouter, patternPath]
    )

    const currentNetworkName = useMemo(() => {
        return currentNetwork?.name ? `${currentNetwork?.name} — ` : ''
    }, [currentNetwork])

    const metaTitle = useMemo(() => {
        return `${currentNetworkName}${routeItem?.meta?.title}`
    }, [routeItem?.meta?.title, currentNetworkName])

    const title = useMemo(() => {
        return routeItem?.meta?.title
    }, [routeItem?.meta?.title])

    const showRequestReviewButton = useMemo(() => {
        return routeItem?.meta?.showRequestReviewButton
    }, [routeItem?.meta?.showRequestReviewButton])

    const hideTitle = useMemo(() => {
        return routeItem?.meta?.hideHeadTitle
    }, [routeItem?.meta?.hideHeadTitle])

    const tabs = useMemo(() => {
        return routeItem?.meta?.tabs
    }, [routeItem?.meta?.tabs])

    useTitle(metaTitle || '')

    const handleMobileMenuToggle = useCallback(() => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }, [isMobileMenuOpen])

    const handleItemMenuClick = useCallback(() => {
        setIsMobileMenuOpen(false)
    }, [])

    const handleRequestButtonClick = useCallback(() => {
        if (networkUuidParam) {
            navigate(Paths.REQUEST_REVIEWS_PATH.replace(':networkUuid', networkUuidParam))
        }
    }, [navigate, networkUuidParam])

    useEffect(() => {
        if (networkUuidParam && networksMap) {
            const newNetworkToCurrent = networksMap.get(networkUuidParam)
            if (newNetworkToCurrent) {
                dispatch(setCurrentNetwork({ network: newNetworkToCurrent }))
            }
        }
    }, [dispatch, networksMap, networkUuidParam])

    useEffect(() => {
        if (!networkUuidParam && networksMap && currentNetwork === null && networks?.length > 0) {
            const newNetworkToCurrent = networksMap.get(networks[0].uuid)
            if (newNetworkToCurrent) {
                dispatch(setCurrentNetwork({ network: newNetworkToCurrent }))
            }
        }
    }, [dispatch, networksMap, networkUuidParam, currentNetwork, networks])

    useEffect(() => {
        if (!networkUuidParam && currentNetwork?.uuid) {
            navigate(Paths.DASHBOARD_PATH.replace(':networkUuid', currentNetwork?.uuid))
        }
    }, [navigate, currentNetwork?.uuid, networkUuidParam])

    return (
        <section className={cn(styles.container, commonStyles.containerHasSidebar)}>
            <SidePop isOpen={isMobileMenuOpen} onClickMenuToggle={handleMobileMenuToggle} zIndex={9999}>
                <SideBar dashboardMatching={dashboardMatching} onMobileMenuToggle={handleItemMenuClick} />
            </SidePop>
            <section className={commonStyles.contentContainer}>
                <div className={styles.headerContainer}>
                    <Header
                        title={title || 'Dashboard'}
                        onClickMenuToggle={handleMobileMenuToggle}
                        hideTitle={Boolean(hideTitle)}
                    />
                    {showRequestReviewButton && (
                        <IconButton
                            id="request-review"
                            classNameWrapper={styles.requestButtonWrapper}
                            onClick={handleRequestButtonClick}
                            disabled={false}
                            name="Request review"
                        />
                    )}
                </div>
                {tabs && (
                    <div className={styles.headerTabsContainer}>
                        <HeadTabs tabs={tabs} dashboardMatching={dashboardMatching} />
                    </div>
                )}
                <main className={commonStyles.content}>{children}</main>
            </section>
        </section>
    )
})
