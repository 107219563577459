import React, { useCallback } from 'react'

// Styles
import cn from 'classnames'
import styles from './styles.module.sass'

type Props = {
    label: string
    onClick: () => void
    disabled?: boolean
    icon: JSX.Element
}

export default function ProfileDropdownItem({ label, onClick, disabled, icon }: Props): JSX.Element {
    const handleClick = useCallback(() => {
        if (!disabled) {
            onClick()
        }
    }, [onClick, disabled])

    return (
        <React.Fragment>
            {!disabled ? (
                <div className={cn(styles.profileDropdownItem)} onClick={handleClick}>
                    {icon && <div className={cn(styles.profileDropdownItem__iconWrap)}>{icon}</div>}
                    <span className={styles.profileDropdownItem__label}>{label}</span>
                </div>
            ) : null}
        </React.Fragment>
    )
}
