import React, { useCallback } from 'react'

// Styles
import cn from 'classnames'
import styles from '@Styles/UiKit/Selects/options.module.sass'

type Props = {
    label: string
    value: number | string
    isSelected: boolean
    onChange: (value: number | string) => void
}

export default function Option({ label, value, isSelected, onChange }: Props): JSX.Element {
    const handleChange = useCallback(() => {
        onChange(value)
    }, [onChange, value])

    return (
        <div
            key={value}
            className={cn(styles.selectOption, {
                [styles.selectOption_selected]: isSelected,
            })}
            onClick={handleChange}
        >
            <span className={styles.selectOption__label}>{label}</span>
        </div>
    )
}
