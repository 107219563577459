import React from 'react'

import { ReactComponent as HouseLogo } from '@Icons/menu/house.svg'
import { ReactComponent as CommensLogo } from '@Icons/menu/comments.svg'
import { ReactComponent as PublicationLogo } from '@Icons/menu/publication.svg'
import { ReactComponent as SettingsIcon } from '@Icons/menu/settings.svg'
import { ReactComponent as UserIcon } from '@Icons/menu/user.svg'
import { ReactComponent as LogoutIcon } from '@Icons/menu/logout.svg'

type Props = {
    name: string
}

export default function MenuIcon({ name }: Props): JSX.Element | null {
    switch (name) {
    case 'house':
        return <HouseLogo />

    case 'comments':
        return <CommensLogo />

    case 'publication':
        return <PublicationLogo />

    case 'settings':
        return <SettingsIcon />

    case 'user':
        return <UserIcon />

    case 'logout':
        return <LogoutIcon />

    default:
        return null
    }
}
