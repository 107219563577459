import React from 'react'
import { useLocation, PathMatch } from 'react-router-dom'
import { Location } from 'history'

// Styles
import styles from './styles.module.sass'
import cn from 'classnames'

// Components
import SideNavItem from './SideNavItem'
import MenuIcon from '@Components/Common/Icons/MenuIcon/MenuIcon'

// Types
import { NavItem } from '@Types/menu'

// Types
import { RouterType } from '@Types/router'

function isActiveItem(item: NavItem, location: Location, networkUuid?: string): boolean {
    return networkUuid
        ? location.pathname === item.url.replace(':networkUuid', networkUuid)
        : location.pathname === item.url
}

type Props = {
    items: NavItem[]
    dashboardMatching: { match: PathMatch; routeConfig: RouterType } | null
    onMobileMenuToggle: () => void
}

export default function SideNav({ items, dashboardMatching, onMobileMenuToggle }: Props): JSX.Element {
    const location = useLocation()
    const match = dashboardMatching?.match
    const networkUuid = match?.params.networkUuid

    return (
        <div className={cn(styles.sideNav)}>
            {items.map((item, index) => {
                return (
                    <SideNavItem
                        key={index}
                        id={item.id}
                        title={item.title}
                        icon={<MenuIcon name={item.iconName} />}
                        url={networkUuid ? item.url.replace(':networkUuid', networkUuid) : item.url}
                        active={isActiveItem(item, location, networkUuid)}
                        onClick={onMobileMenuToggle}
                    />
                )
            })}
        </div>
    )
}
