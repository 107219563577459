import { lazy } from 'react'
import { RouterType } from '@Types/router'
import { LOGIN_PATH, RESET_PASSWORD_PATH } from '@Constants/paths'

const UserLayoutRoutes: RouterType[] = [
    {
        path: LOGIN_PATH,
        meta: {
            title: 'Sign In',
        },
        component: lazy(() => import('@Pages/Auth/LoginPage/LoginPage')),
    },
    {
        path: RESET_PASSWORD_PATH,
        meta: {
            title: 'Restore password',
        },
        component: lazy(() => import('@Pages/Auth/RestorePage/RestorePage')),
    },
]

export default UserLayoutRoutes
