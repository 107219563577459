import type { TypedAddListener } from '@reduxjs/toolkit'
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit'

// Types
import { AppDispatch, AppStartListening, RootState } from '../store'

const listener = createListenerMiddleware()

export const { middleware: listenerMiddleware } = listener

export const startAppListening = listener.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>
