import React from 'react'
import { PathMatch } from 'react-router-dom'

// Components
import SideNav from './SideNav'

// Constants
import { MainNavItems } from '@Constants/menu'

// Types
import { RouterType } from '@Types/router'

type Props = {
    dashboardMatching: { match: PathMatch; routeConfig: RouterType } | null
    onMobileMenuToggle: () => void
}

export default function MainSideNav({ dashboardMatching, onMobileMenuToggle }: Props): JSX.Element {
    return (
        <SideNav items={MainNavItems} dashboardMatching={dashboardMatching} onMobileMenuToggle={onMobileMenuToggle} />
    )
}
