import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import request from '@Utils/request'
import { startAppListening } from '@Store/middleware'

import { concatUniqueByUuid } from '@Utils/redux'

// Types
import {
    ReviewsState,
    RequestReviewsLoad,
    RequestReviewReply,
    Review,
    RequestReviewsCountsLoad,
    ReviewResponseCounts,
} from '@Types/store/reviews'

import { RequestState } from '@Types/enums/requestState'
import { LoadingDataState } from '@Types/enums/loadingDataState'

export const loadReviews = createAsyncThunk(
    'reviews/load',
    async (params: RequestReviewsLoad, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/reviews`,
                method: 'GET',
                params: params.filterParams,
            })

            return response.data as AxiosResponse<{ reviews: Review[] }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const loadReviewsCounts = createAsyncThunk(
    'reviews/counts',
    async (params: RequestReviewsCountsLoad, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/review-full-counts-data`,
                method: 'GET',
                params: params.filterParams,
            })

            return response.data as AxiosResponse<{ reviewFullCountsData: ReviewResponseCounts }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const doReviewReply = createAsyncThunk(
    'review/reply',
    async (params: RequestReviewReply, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/reviews/${params.reviewUuid}`,
                method: 'PATCH',
                data: params.data,
            })

            return response.data as AxiosResponse<{ reviews: Review[] }>
        } catch (err: any) {
            if (!err?.response) {
                throw err
            }

            return rejectWithValue(err.response.data)
        }
    }
)

export const updateReview = createAsyncThunk(
    'reviews/updateReview',
    async (params: RequestReviewsLoad, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await request({
                url: `/networks/${params.networkUuid}/reviews`,
                method: 'GET',
                params: params.filterParams,
            })

            return response.data as AxiosResponse<{ reviews: Review[] }>
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const initialState = {
    loading: LoadingDataState.IDLE,
    loadingUpdateReview: LoadingDataState.IDLE,
    loadingCounts: LoadingDataState.IDLE,
    loadingSentReply: RequestState.IDLE,
    reviews: [],
    counts: null,
    firstLoading: true,
} as ReviewsState

const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        updateReviews(state, action) {
            state.reviews = action.payload.updateReviews || []
        },
        clearLoadingState(state) {
            state.loading = LoadingDataState.IDLE
            state.firstLoading = true
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadReviews.pending, state => {
                if (state.loading !== LoadingDataState.IDLE) {
                    state.firstLoading = false
                }
                state.loading = LoadingDataState.LOADING
            })
            .addCase(loadReviews.fulfilled, state => {
                state.loading = LoadingDataState.LOADED
                // Обновление списка отзывов просходит в мидлваре и редьюсере UpdateReviews
            })
            .addCase(loadReviews.rejected, state => {
                if (state.loading === LoadingDataState.LOADING) {
                    state.loading = LoadingDataState.ERROR
                }
            })

            .addCase(loadReviewsCounts.pending, state => {
                state.loadingCounts = LoadingDataState.LOADING
            })
            .addCase(loadReviewsCounts.fulfilled, (state, action: any) => {
                state.loadingCounts = LoadingDataState.LOADED
                state.counts = action.payload.reviewFullCountsData
            })
            .addCase(loadReviewsCounts.rejected, state => {
                if (state.loadingCounts === LoadingDataState.LOADING) {
                    state.loadingCounts = LoadingDataState.ERROR
                }
            })

            .addCase(doReviewReply.pending, state => {
                state.loadingSentReply = RequestState.PENDING
            })
            .addCase(doReviewReply.fulfilled, (state, action: any) => {
                state.loadingSentReply = RequestState.SUCCESS
            })
            .addCase(doReviewReply.rejected, state => {
                if (state.loadingSentReply === RequestState.PENDING) {
                    state.loadingSentReply = RequestState.IDLE
                }
            })

            .addCase(updateReview.pending, state => {
                state.loadingUpdateReview = LoadingDataState.LOADING
            })
            .addCase(updateReview.fulfilled, (state, action: any) => {
                state.loadingUpdateReview = LoadingDataState.LOADED
            })
            .addCase(updateReview.rejected, state => {
                if (state.loadingUpdateReview === LoadingDataState.LOADING) {
                    state.loadingUpdateReview = LoadingDataState.ERROR
                }
            })
    },
})

export const { updateReviews, clearLoadingState } = reviewsSlice.actions

export default reviewsSlice.reducer

// После загрузки отзывов, если это была загрузка loadMore то объединяем старые и новые отзывы
//в другом случае просто обновляем список на новый
startAppListening({
    type: 'reviews/load/fulfilled',
    effect: async (action: any, { dispatch, getState }) => {
        const newReview = action.payload.reviews
        const loadMore = action.meta.arg.loadMore

        if (loadMore) {
            const { reviews } = getState()
            dispatch(updateReviews({ updateReviews: concatUniqueByUuid(reviews.reviews, newReview) }))
        } else {
            dispatch(updateReviews({ updateReviews: newReview }))
        }
    },
})

// После ответа на отзыв, получаем его обновленные данные
startAppListening({
    type: 'review/reply/fulfilled',
    effect: async (action: any, { dispatch }) => {
        const updateUuid = action.meta.arg.reviewUuid
        const networkUuid = action.meta.arg.networkUuid
        const params = {
            networkUuid: networkUuid,
            filterParams: { limit: 1, uuid: updateUuid },
            loadMore: false,
        }
        dispatch(updateReview(params))
    },
})

// После ответа на отзыв, получаем его обновленные данные
startAppListening({
    type: 'reviews/updateReview/fulfilled',
    effect: async (action: any, { dispatch, getState }) => {
        const { reviews } = getState()
        const updateReview = action.payload.reviews[0]

        const updateReviewsArr = reviews.reviews.map(review => {
            return updateReview.uuid === review.uuid ? updateReview : review
        })

        if (updateReviewsArr.length > 0) {
            dispatch(updateReviews({ updateReviews: updateReviewsArr }))
        }
    },
})
