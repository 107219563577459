import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Redux
import { useDispatch } from 'react-redux'
import { doUserLogout } from '@Slices/userSlice'
import { AppDispatch } from '@Store/store'

// Styles
import cn from 'classnames'
import styles from './styles.module.sass'

// Components
import ProfileDropdownItem from './ProfileDropdownItem'
import MenuIcon from '@Components/Common/Icons/MenuIcon/MenuIcon'

import * as Paths from '@Constants/paths'

const ACCOUNT_MENU_SHOW = false

export default function ProfileDropdown(): JSX.Element {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const handleLogoutClick = useCallback(() => {
        dispatch(doUserLogout()).then((res: any) => {
            if (!res.error) {
                navigate(Paths.LOGIN_PATH)
            }
        })
    }, [dispatch, navigate])

    return (
        <div className={cn(styles.profileDropdown)}>
            <ProfileDropdownItem
                label="Account"
                onClick={handleLogoutClick}
                disabled={!ACCOUNT_MENU_SHOW}
                icon={<MenuIcon name="user" />}
            />
            {ACCOUNT_MENU_SHOW && <div className={cn(styles.profileDropdown__separate)} />}
            <ProfileDropdownItem label="Logout" onClick={handleLogoutClick} icon={<MenuIcon name="logout" />} />
        </div>
    )
}
